import * as React from "react";
import * as styles from "./kontakt.module.scss";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo";
import {StaticImage} from "gatsby-plugin-image";

const Contact = () => {
  return (
    <Layout light={true} noContactIcons={true}>
      <Seo title="Kontakt" />
      <div className={styles.container}>
        <div>
          <section className={styles.contactSection}>
            <h2 className={styles.sectionTitle}>
              Sídlo společnosti
            </h2>
            <h3 className={styles.companyName}>Ladema s.r.o.</h3>
            <p>Na Folimance 2155/15</p>
            <p>Vinohrady, 120 00 Praha 2</p>
            <p className={styles.ic}>IČ: 10715207</p>
          </section>
          <section className={styles.contactSection}>
            <h2 className={styles.sectionTitle}>
              E-mail
            </h2>
            <h3 className={styles.contactDetail}>
              <a href="mailto:info@ladema.cz" title="Poslat mail">
                <i className="fal fa-envelope" /> info@ladema.cz
              </a>
            </h3>
          </section>
          <section className={styles.contactSection}>
            <h2 className={styles.sectionTitle}>
              Telefon
            </h2>
            <h3 className={styles.contactDetail}>
              <a href="tel:00420724946065" title="Zavolat">
                <i className="fal fa-phone-rotary" /> +420 724 946 065
              </a>
            </h3>
          </section>
        </div>

        <div className={styles.note}>
          <h4>Osobní schůzku je nutné nejprve domluvit telefonicky nebo emailem.</h4>
          <StaticImage src="../../images/D_light.png" alt="logo" className={styles.logo} />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
